<!--品牌商户-->
<template>
  <div id="merchant">
    <section class="table-search">
      <div class="search-L"></div>
      <div class="search-R">
        <el-button type="primary" @click="goAdd()">新增品牌商户</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="品牌商户名" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.brandMerchantName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="注册时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.addDt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="超管姓名" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.userName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="超管手机" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.userMobile }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商户号" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.merchantNo }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      :visible.sync="editShow"
      title="新增品牌超管"
      :close-on-click-modal="false"
      width="30%"
      custom-class="add-dialog"
    >
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 商户名称 -->
        <el-form-item
          label="商户名称"
          label-width="120px"
          prop="brandMerchantName"
        >
          <el-input
            v-model.trim="params.brandMerchantName"
            autocomplete="off"
            maxlength="16"
            placeholder="请填写商户信息"
          ></el-input>
        </el-form-item>
        <!-- 商户法人 -->
        <el-form-item label="商户号" label-width="120px" prop="merchantNo">
          <el-input
            v-model.trim="params.merchantNo"
            autocomplete="off"
            maxlength="16"
            placeholder="请填写商户号"
          ></el-input>
        </el-form-item>
        <!-- 商户超管 -->
        <el-form-item label="商户超管" label-width="120px" prop="brandUserName">
          <el-input
            v-model.trim="params.brandUserName"
            autocomplete="off"
            maxlength="16"
            placeholder="请填写商户超管"
          ></el-input>
        </el-form-item>
        <!-- 超管手机 -->
        <el-form-item label="超管手机" label-width="120px" prop="userMobile">
          <el-input
            type="number"
            v-model="params.userMobile"
            autocomplete="off"
            placeholder="请填写超管手机"
          ></el-input>
        </el-form-item>
        <!-- 营业执照 -->
        <el-form-item
          label="营业执照"
          label-width="120px"
          prop="businessLicenseFileId"
        >
          <div class="upload-btn" @click="onUpload()">
            <img
              v-if="params.licenseUrl"
              :src="params.licenseUrl"
              class="upload-img"
            />
            <i v-else class="el-icon-plus uploader-icon"></i>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div>
          <el-button @click="editShow = false">取 消</el-button>
          <el-button type="primary" @click="onSubmit('form-ref')"
            >保存</el-button
          >
        </div>
        <div class="tips">tips：创建后不支持编辑修改，请谨慎填写</div>
      </div>
    </el-dialog>
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>
<script>
import {
  getBrandMerchantList,
  goAddBrandMerchant,
} from "@/api/brand/merchant.js";
import { rules } from "@/db/rules";
import UpLoad from "@/components/upload";
import { getFileList } from "@/api/common";
export default {
  components: { UpLoad },
  data() {
    return {
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 10,
      },
      rules,
      editShow: false, //弹框开关
      params: {
        brandMerchantName: "", //商户名称
        merchantNo: "", //商户号
        brandUserName: "", //商户超管姓名
        userMobile: null, //超管手机
        businessLicenseFileId: "", //营业执照
        licenseUrl: "", //营业执照图片
        imageId: "",
      },
    };
  },
  mounted() {
    this.getBrandMerchantList();
  },
  methods: {
    // 查询品牌商户列表
    getBrandMerchantList() {
      getBrandMerchantList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      });
    },
    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload();
    },
    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.params.imageId = fileIds;
      this.getFileList(fileIds);
    },
    // 新增品牌超管
    goAddMerchant() {
      let params = {
        brandMerchantName: this.params.brandMerchantName,
        merchantNo: this.params.merchantNo,
        userName: this.params.brandUserName,
        userMobile: this.params.userMobile,
        imageId: this.params.businessLicenseFileId,
      };
      goAddBrandMerchant(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getBrandMerchantList();
        }
      });
    },
    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.goAddMerchant();
        } else {
          return false;
        }
      });
    },
    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds,
      };
      getFileList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.params.licenseUrl = res.data[0].url;
        }
      });
    },
    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.params.businessLicenseFileId = fileIds;
      this.getFileList(fileIds);
    },
    // 新增商户
    goAdd() {
      this.params = {
        brandMerchantName: "", //商户名称
        merchantNo: "", //商户号
        brandUserName: "", //商户超管姓名
        userMobile: null, //超管手机
        businessLicenseFileId: "", //营业执照
        licenseUrl: "", //营业执照图片
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
      this.editShow = true;
    },
    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),
    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getBrandMerchantList();
    },
    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getBrandMerchantList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.getBrandMerchantList();
    },
  },
};
</script>
<style lang="scss" scoped>
#merchant {
  padding: 0.15rem;
  box-sizing: border-box;
  .table-search {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.15rem;
  }

  .search-L {
    display: flex;
  }
  .table-footer {
    margin-top: 0.1rem;
    display: flex;
    justify-content: flex-end;
  }
  .upload-btn {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
  }

  .upload-btn:hover {
    border-color: #409eff;
  }

  .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1.6rem;
    height: 1.6rem;
    line-height: 1.6rem;
    text-align: center;
  }

  .upload-img {
    width: 1.6rem;
    height: 1.6rem;
    display: block;
  }
  .tips {
    margin-top: 10px;
    font-size: 14px;
    color: red;
  }
}
</style>
