import request from '@/utils/request'
// 省市区三级联动
export function getCityList(data) {
  return request('post', '/aps/sysArea/threeLevel', data)
}

// 获取文件列表
export function getFileList(data) {
  return request('post', '/aps/file/getFiles', data)
}

// 发送手机验证码
export function goSendMobileCode(data) {
  return request('post', '/apa/sms/send', data)
}

// 校验验证码
export function goCheckMobileCode(data) {
  return request('post', '/apa/sms/check', data)
}

// 修改密码
export function goChangePassword(data) {
  return request('post', '/apa/user/pcUpdatePassword', data)
}
// 获取图片验证码
export function getImageCode() {
  return request('get', '/apa/user/get/image/code')
}