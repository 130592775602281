import request from "@/utils/request";

// 品牌商户管理新增
export function goAddBrandMerchant(data) {
  return request("post", "/apm/brand/merchant/add", data);
}

// 品牌商户管理删除
export function goDeleteBrandMerchant(data) {
  return request("post", "/apm/brand/merchant/delById", data);
}

// 品牌商户管理列表分页
export function getBrandMerchantList(data) {
  return request("post", "/apm/brand/merchant/page", data);
}

// 品牌商户管理列表不分页
export function getAllBrandMerchantList(data) {
    return request("post", "/apm/brand/merchant/selectList", data);
  }
  // 品牌商户管理列表不分页(分摊)
export function getShareAllBrandMerchantList(data) {
  return request("post", "/apm/brand/merchant/share/selectList", data);
}
  